// @ts-check

import React, { useState } from "react";
import { useSelector } from "react-redux";
import debug from "debug";

import * as api from "../api";

import { getBricks } from "../features/sceneSlice";
import { getSceneId } from "../features/uiSlice";

import FormButton from "./FormButton";

// @ts-ignore
import * as styles from "../styles/components/save-scene-form.less";

const log = debug("app:components:edit-scene");

export default function EditScene({ onSuccess }) {
  const [sceneChanged, setSceneChanged] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(/** @type {null | string} */ (null));
  const sceneId = useSelector(getSceneId);
  const bricks = useSelector(getBricks);

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      if (!sceneId) {
        return;
      }

      setLoading(true);
      setError(null);

      const { data: dbBricks } = await api.getScene(sceneId);

      if (bricks.length === dbBricks.length) {
        const allObjectsEqual = didBricksChange(bricks, dbBricks);

        if (allObjectsEqual) {
          setError("Nem történt változtatás a terven!");
          setSceneChanged(false);
          return;
        }
      }

      const { id } = await api.updateScene(sceneId, bricks);
      log("Update scene successful", { id });

      setLoading(false);
      setError(null);
      onSuccess(true);
    } catch (error) {
      log("Update scene failed", { error, sceneId });
      setError("Hiba történt a terv frissítése közben! Kérjük próbálja újra!");
    }
  }

  return (
    <form className={styles.saveSceneForm}>
      <div>
        <p>Biztosan felülírja a meglévő tervrajzát?</p>
        <div>
          {error && <div className={styles.error}>{error}</div>}
          {sceneChanged && (
            <FormButton
              type={"submit"}
              className={styles.button}
              onClick={handleSubmit}
              loading={loading}
            >
              Mentés
            </FormButton>
          )}
        </div>
      </div>
    </form>
  );
}

function didBricksChange(bricks, dbBricks) {
  if (bricks.length !== dbBricks.length) {
    return true;
  }
  return bricks.every((sb) => {
    return dbBricks.some((db) => sb.customId === db.customId);
  });
}
