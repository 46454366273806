// @ts-check

import * as THREE from "three";
import { PLANE_COLOR } from "../../../utils/constants";
// @ts-ignore
import PlaneImg from "../../../../static/plane.png";

export class Plane extends THREE.Mesh {
  constructor(size, maxAnysotropy) {
    const geometry = new THREE.PlaneGeometry(size, size);
    geometry.rotateX(-Math.PI / 2);
    const texture = new THREE.TextureLoader().load(PlaneImg);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(20, 20);
    texture.anisotropy = maxAnysotropy;
    const planeMaterial = new THREE.MeshBasicMaterial({ map: texture });
    planeMaterial.opacity = 1;

    super(geometry, planeMaterial);
    this.receiveShadow = true;
  }
}
