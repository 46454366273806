// @ts-check

import React from "react";

// @ts-ignore
import * as styles from "../styles/components/kbd.less";

export default function Kbd({ keys, separator = "+" }) {
  return (
    <>
      {keys.map((key, idx) => (
        <span key={idx}>
          <kbd className={styles.kbd}>
            <span key={idx}>{key}</span>
          </kbd>
          {idx !== keys.length - 1 && (
            <span className={styles.plus}>{separator}</span>
          )}
        </span>
      ))}
    </>
  );
}
