// @ts-check

/// <reference path="./types.d.ts" />

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as THREE from "three";
import { useIsFirstRender, useEventListener } from "usehooks-ts";

import Detector from "../../utils/threejs/Detector";
import Brick from "./Brick";
import { RollOverBrick } from "./Helpers";
import {
  PerspectiveCamera,
  Controls,
  AmbientLight,
  Light,
  Plane,
  Renderer,
} from "./core";
import {
  getMeasurementsFromDimensions,
  makeVectorsFromSegments,
} from "../../utils";
import {
  base,
  GRID_CENTERLINE_COLOR,
  GRID_COLOR,
  MODE_NAMES,
} from "../../utils/constants";

import { addBrick, removeBrick, getBricks } from "../../features/sceneSlice";
import { getIsSceneActive } from "../../features/uiSlice";

import {
  getMode,
  getColor,
  getBrickDimensions,
  getRotation,
  getLoading,
  getLoadError,
} from "../../features/builderSlice";

// @ts-ignore
import * as styles from "../../styles/components/scene.less";

function Scene() {
  const mode = useSelector(getMode);
  const color = useSelector(getColor);
  const dimensions = useSelector(getBrickDimensions);
  //const brickState = useSelector(getBricks);
  //const objects = brickState.map((b) => new Brick(b));
  const objects = useSelector(getBricks);
  const rotation = useSelector(getRotation);
  const isLoading = useSelector(getLoading);
  const loadError = useSelector(getLoadError);

  const dispatch = useDispatch();
  const handleRemoveBrick = (payload) => dispatch(removeBrick(payload));
  const handleAddBrick = (payload) => dispatch(addBrick(payload));

  const [drag, setDrag] = useState(false);

  const isFirstRender = useIsFirstRender();
  const sceneRef = useRef(/** @type {any} */ (null));
  const rendererRef = useRef(/** @type {Renderer | null} */ (null));
  const cameraRef = useRef(/** @type {PerspectiveCamera | null} */ (null));
  const controlsRef = useRef(/** @type {Controls | null} */ (null));
  const mountRef = useRef(/** @type {HTMLDivElement | null} */ (null));
  const rollOverBrickRef = useRef(/** @type {RollOverBrick | null} */ (null));
  const delRollOverBrickRef = useRef(
    /** @type {RollOverBrick | null} */ (null)
  );
  const planeRef = useRef(/** @type {Plane | null} */ (null));
  const gridRef = useRef(/** @type {any | null} */ (null));
  const raycasterRef = useRef(/** @type {any | null} */ (null));
  const mouseRef = useRef(/** @type {any | null} */ (null));

  const coreObjects = useRef(/** @type {any[]} */ ([]));

  const rollOverBrickColor = { orginal: 0x08173d, red: 0xff0000 };
  const maxCheckDistance = base * 12;

  function getOnSceneBricks() {
    return sceneRef.current?.children.filter((o) => o instanceof Brick) || [];
  }
  let onSceneBricks = getOnSceneBricks();

  // Init core & utils
  useEffect(() => {
    if (!Detector.webgl) Detector.addGetWebGLMessage();

    function animate() {
      controlsRef.current?.update();
      // @ts-ignore
      rendererRef.current?.render(sceneRef.current, cameraRef.current);
      requestAnimationFrame(animate);
    }

    sceneRef.current = new THREE.Scene();
    rendererRef.current = new Renderer({ antialias: true });
    cameraRef.current = new PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    controlsRef.current = new Controls(
      cameraRef.current,
      rendererRef.current?.domElement
    );
    rollOverBrickRef.current = new RollOverBrick(color, dimensions);
    delRollOverBrickRef.current = new RollOverBrick(color, dimensions);
    // @ts-ignore
    delRollOverBrickRef.current.material.color.setHex(rollOverBrickColor.red);
    delRollOverBrickRef.current.visible = false;
    const light = new Light();
    const ambientLight = new AmbientLight(0xffffff, 2);
    const pointLight = new THREE.PointLight(0xffffff, 3, 8000, 0);
    pointLight.position.set(-2000, 3000, 1000);
    const maxAnisotropy = rendererRef.current.capabilities.getMaxAnisotropy();
    planeRef.current = new Plane(6000, maxAnisotropy);

    gridRef.current = new THREE.GridHelper(
      6000,
      200,
      new THREE.Color(GRID_CENTERLINE_COLOR),
      new THREE.Color(GRID_COLOR)
    );
    gridRef.current.visible = false;

    raycasterRef.current = new THREE.Raycaster();
    mouseRef.current = new THREE.Vector2();
    coreObjects.current = [
      light,
      ambientLight,
      pointLight,
      planeRef.current,
      gridRef.current,
      rollOverBrickRef.current,
      delRollOverBrickRef.current,
    ];

    light.init();
    cameraRef.current.init();
    controlsRef.current.init();

    mountRef.current?.appendChild(rendererRef.current.domElement);
    rendererRef.current.init(window.innerWidth, window.innerHeight);
    animate();
  }, []);

  function onTouchMove(event) {
    event.preventDefault();
    toggleGrid();
  }

  useEventListener("touchmove", onTouchMove);

  function onMouseMove(event) {
    event.preventDefault();
    setDrag(true);
    rollOverBrickMove(event);
    toggleGrid();
  }

  useEventListener("mousemove", onMouseMove);

  function onMouseDown() {
    setDrag(false);
  }

  // onMouseDown
  useEventListener("mousedown", onMouseDown);

  function onMouseUp(event) {
    if (event.target.localName !== "canvas") {
      return;
    }
    event.preventDefault();
    if (drag) {
      return;
    }
    mouseRef.current.set(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );
    raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);
    if ([MODE_NAMES.BUILD, MODE_NAMES.DELETION].includes(mode) === false) {
      return;
    }
    const intersects = raycasterRef.current.intersectObjects([
      ...onSceneBricks,
      planeRef.current,
    ]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      if (mode === MODE_NAMES.BUILD) {
        createCube(intersect, rollOverBrickRef.current);
      } else if (mode === MODE_NAMES.DELETION) {
        deleteCube(intersect);
      }
    }
    rollOverBrickMove({
      clientX: (mouseRef.current.x + 1) * (window.innerWidth / 2),
      clientY: -(mouseRef.current.y - 1) * (window.innerHeight / 2),
    });
  }

  // onMouseUp
  useEventListener("mouseup", onMouseUp);

  function onWindowResize() {
    if (cameraRef.current && rendererRef.current) {
      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    }
  }

  // onWindowResize
  useEventListener("resize", onWindowResize);

  useEffect(() => {
    // rollOverBrick config
    if (
      mode === "build" &&
      rollOverBrickRef.current &&
      delRollOverBrickRef.current
    ) {
      rollOverBrickRef.current.visible = true;
      delRollOverBrickRef.current.visible = false;
    } else if (rollOverBrickRef.current) {
      rollOverBrickRef.current.visible = false;
    }
    rollOverBrickMove({
      clientX: (mouseRef.current.x + 1) * (window.innerWidth / 2),
      clientY: -(mouseRef.current.y - 1) * (window.innerHeight / 2),
    });
  }, [mode]);

  useEffect(() => {
    sceneRef.current.children = [...coreObjects.current];
  }, []);

  useEffect(() => {
    if (onSceneBricks.length !== objects.length) {
      if (onSceneBricks.length > objects.length) {
        const removeFromScene = onSceneBricks.filter(
          (b) => !objects.some((o) => b.customId === o.customId)
        );
        removeFromScene.forEach((removeBrick) => {
          sceneRef.current.remove(removeBrick);
          removeBrick.geometry.dispose();
        });
      } else {
        const addToScene = objects.filter(
          (o) => !onSceneBricks.some((b) => b.customId === o.customId)
        );
        addToScene.forEach((addBrick) => {
          const brick = new Brick(addBrick);
          sceneRef.current.add(brick);
        });
      }
      onSceneBricks = getOnSceneBricks();
      rollOverBrickMove({
        clientX: (mouseRef.current.x + 1) * (window.innerWidth / 2),
        clientY: -(mouseRef.current.y - 1) * (window.innerHeight / 2),
      });
    }
  }, [objects]);

  useEffect(() => {
    rollOverBrickRef.current?.setShape(dimensions);
  }, [dimensions.x, dimensions.z]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    rollOverBrickRef.current?.rotate(Math.PI / 2);
    rollOverBrickMove({
      clientX: (mouseRef.current.x + 1) * (window.innerWidth / 2),
      clientY: -(mouseRef.current.y - 1) * (window.innerHeight / 2),
    });
  }, [rotation]);

  const matchMediaRef = useRef(
    window.matchMedia("screen and (min-resolution: 2dppx)")
  );

  const onResolutionChange = (e) => {
    console.log("changing resolution", e);
    if (e.matches) {
      rendererRef.current?.setPixelRatio(window.devicePixelRatio);
    } else {
      rendererRef.current?.setPixelRatio(1);
    }
  };

  useEventListener("change", onResolutionChange, matchMediaRef);

  function createCube(intersect, rollOverBrick) {
    if (isBrickPositionValidAtBuild()) {
      const { translation, rotation } = rollOverBrick;

      // @ts-ignore
      const brick = new Brick({
        intersect: intersect,
        color,
        dimensions: dimensions,
        rotation: rotation.y,
        translation: translation,
      });
      handleAddBrick({ brick: brick.toPOJO() });
    }
  }

  function deleteCube(intersect) {
    if (isBrickPositionValidAtDelete(intersect)) {
      handleRemoveBrick({ id: intersect.object.customId });
    }
  }

  function rollOverBrickMove(event) {
    // @ts-ignore
    const { height } = getMeasurementsFromDimensions(dimensions);
    const evenWidth = dimensions.x % 2 === 0;
    const evenDepth = dimensions.z % 2 === 0;
    mouseRef.current.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouseRef.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);

    const intersects = raycasterRef.current.intersectObjects(
      [...onSceneBricks, planeRef.current],
      true
    );
    if (intersects.length > 0) {
      const intersect = intersects[0];
      if (mode === MODE_NAMES.BUILD && rollOverBrickRef.current) {
        rollOverBrickRef.current?.position
          .copy(intersect.point)
          .add(intersect.face.normal);
        rollOverBrickRef.current?.position
          .divide(new THREE.Vector3(base, height, base))
          .floor()
          .multiply(new THREE.Vector3(base, height, base))
          .add(
            new THREE.Vector3(
              evenWidth ? base : base / 2,
              height / 2,
              evenDepth ? base : base / 2
            )
          );
        if (!isBrickPositionValidAtBuild()) {
          // @ts-ignore
          rollOverBrickRef.current.material.color.setHex(
            rollOverBrickColor.red
          );
        } else {
          // @ts-ignore
          rollOverBrickRef.current.material.color.setHex(
            rollOverBrickColor.orginal
          );
        }
      }
      if (mode === MODE_NAMES.DELETION && delRollOverBrickRef.current) {
        if (
          !isBrickPositionValidAtDelete(intersect) &&
          intersect.object !== planeRef.current
        ) {
          if (
            intersect.object._dimensions.x !==
              delRollOverBrickRef.current.dimensions.x ||
            intersect.object._dimensions.z !==
              delRollOverBrickRef.current.dimensions.z
          ) {
            delRollOverBrickRef.current?.setShape(intersect.object._dimensions);
          }
          if (
            delRollOverBrickRef.current.rotation.y !==
            intersect.object.rotation.y
          ) {
            delRollOverBrickRef.current.rotate(Math.PI / 2);
          }
          delRollOverBrickRef.current?.position.set(
            intersect.object.position.x,
            intersect.object.position.y,
            intersect.object.position.z
          );
          delRollOverBrickRef.current.visible = true;
        } else {
          delRollOverBrickRef.current.visible = false;
        }
      }
    }
  }

  function isBrickPositionValidAtBuild() {
    let canCreate = true;
    let notFloating = false;
    let segmentsNotFloating = 0;
    let segmentsToCheck = [];
    // @ts-ignore
    const { width, depth } = getMeasurementsFromDimensions(dimensions);
    const meshBoundingBox = new THREE.Box3().setFromObject(
      // @ts-ignore
      rollOverBrickRef.current
    );
    // If the mesh's y coordinate is below 7, it stands on the ground. Otherwise, all segments of the mesh need to check that it stands on another object
    if (meshBoundingBox.min.y < 7) {
      notFloating = true;
    } else {
      segmentsToCheck = makeVectorsFromSegments(meshBoundingBox, "create");
    }

    const meshPostionX = (meshBoundingBox.max.x + meshBoundingBox.min.x) / 2;
    const meshPostionZ = (meshBoundingBox.max.z + meshBoundingBox.min.z) / 2;

    for (var i = 0; i < onSceneBricks.length; i++) {
      const checkX = Math.abs(meshPostionX - onSceneBricks[i].position.x);
      const checkZ = Math.abs(meshPostionZ - onSceneBricks[i].position.z);

      if (checkX < maxCheckDistance && checkZ < maxCheckDistance) {
        const brickBoundingBox = new THREE.Box3().setFromObject(
          onSceneBricks[i]
        );
        const collision = meshBoundingBox.intersectsBox(brickBoundingBox);
        if (collision) {
          const dx = Math.abs(brickBoundingBox.max.x - meshBoundingBox.max.x);
          const dz = Math.abs(brickBoundingBox.max.z - meshBoundingBox.max.z);
          const yIntsersect =
            brickBoundingBox.max.y - 9 > meshBoundingBox.min.y;
          if (yIntsersect && dx !== width && dz !== depth) {
            canCreate = false;
            break;
          }

          // Inspects if any of the objects contains any of the mesh segments' vector
          if (!notFloating) {
            segmentsToCheck.forEach((segment) => {
              if (brickBoundingBox.containsPoint(segment)) {
                segmentsNotFloating++;
              }
            });
          }
        }
      }
    }

    if (segmentsToCheck && segmentsNotFloating === segmentsToCheck.length)
      notFloating = true;

    return canCreate && notFloating ? true : false;
  }

  function isBrickPositionValidAtDelete(intersect) {
    if (intersect.object === planeRef.current) return;
    let nothingOnTop = true;
    const deleteBoundingBox = new THREE.Box3().setFromObject(intersect.object);
    const segmentsToCheck = makeVectorsFromSegments(
      deleteBoundingBox,
      "delete"
    );

    const meshPostionX =
      (deleteBoundingBox.max.x + deleteBoundingBox.min.x) / 2;
    const meshPostionZ =
      (deleteBoundingBox.max.z + deleteBoundingBox.min.z) / 2;

    for (let i = 0; i < onSceneBricks.length; i++) {
      const checkX = Math.abs(meshPostionX - onSceneBricks[i].position.x);
      const checkZ = Math.abs(meshPostionZ - onSceneBricks[i].position.z);
      if (
        onSceneBricks[i] !== intersect.object &&
        checkX < maxCheckDistance &&
        checkZ < maxCheckDistance
      ) {
        const brickBoundingBox = new THREE.Box3().setFromObject(
          onSceneBricks[i]
        );
        for (let s = 0; s < segmentsToCheck.length; s++) {
          if (brickBoundingBox.containsPoint(segmentsToCheck[s])) {
            return;
          }
        }
      }
    }
    return nothingOnTop;
  }

  function toggleGrid() {
    // @ts-ignore
    cameraRef.current?.position.y <= 0.1
      ? (gridRef.current.visible = true)
      : (gridRef.current.visible = false);
  }

  let cursor = "default";
  if (mode === MODE_NAMES.MOVE) cursor = "move";
  else if (mode === MODE_NAMES.DELETION) cursor = "crosshair";

  return (
    <div>
      <div style={{ cursor: cursor }} ref={mountRef} />
      {isLoading || loadError ? (
        <div className={styles.loading}>
          <span>{loadError ? loadError : "Adatok betöltése..."}</span>
        </div>
      ) : null}
    </div>
  );
}

export default Scene;
