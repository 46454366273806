import React, { useRef, useEffect, useState } from "react";
import * as styles from "../styles/components/modal.less";

export default function Modal({
  isOpen,
  hasCloseBtn = true,
  onClose,
  children,
  size = "md",
}) {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef(null);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  const className =
    size === "md"
      ? styles.modalMd
      : size === "lg"
        ? styles.modalLg
        : styles.modalXl;

  return (
    <dialog ref={modalRef} onKeyDown={handleKeyDown} className={className}>
      {isOpen && (
        <div className={styles.modalContent}>
          {hasCloseBtn && (
            <button
              className={styles.closeButton}
              onClick={handleCloseModal}
              type="button"
            >
              <i className="ion-close" title="Bezárás" />
            </button>
          )}
          {children}
        </div>
      )}
    </dialog>
  );
}
