// @ts-check

import React, { useRef, useState } from "react";

import { getBricks } from "../features/sceneSlice";

// @ts-ignore
import * as styles from "../styles/components/stats";
import { useSelector } from "react-redux";
import { getPriceList } from "../features/builderSlice";
import Modal from "./Modal";

export default function Stats() {
  const bricks = useSelector((/** @type {any} */ state) => getBricks(state));
  const priceList = useSelector(getPriceList);

  const openDetailsRef = useRef(null);
  const [widgetState, setWidgetState] = useState("open"); // open, closed, modal

  const brickCounts = countBricks(bricks);
  const totalPrice = getTotalPrice(brickCounts, priceList);
  const formattedBricks = getFormattedBricks(brickCounts);
  const brickDetails = getBrickDetails(brickCounts, priceList);

  function handleOpenModal(e) {
    e.stopPropagation();
    setWidgetState("modal");
  }

  function handleWidgetClick(e) {
    console.log(e.target);
    console.log(openDetailsRef.current);
    if (e.target === openDetailsRef.current) {
      return;
    }
    if (widgetState === "open") {
      setWidgetState("closed");
    }
    if (widgetState === "closed") {
      setWidgetState("open");
    }
  }

  return (
    <div className={styles.stats}>
      <div className={styles.iconWrapper} onClick={handleWidgetClick}>
        <div className={styles.icon}>
          <i className="ion-stats-bars" />
        </div>
        <div
          className={widgetState === "closed" ? styles.hidden : styles.title}
        >
          Felhasznált kockák
        </div>
        <div className={widgetState === "closed" ? styles.hidden : ""}>
          {bricks.length > 0 ? (
            <div>
              {formattedBricks.map((brick, idx) => (
                <div key={idx} className={styles.text}>
                  {brick}
                </div>
              ))}
              <div className={styles.line}></div>
              <div className={styles.sumAllPrice}>Végösszeg: {totalPrice}</div>
              <button
                ref={openDetailsRef}
                className={styles.detailsButton}
                onClick={handleOpenModal}
              >
                Részletek
                <div className={styles.detailsIcon}>
                  <i className="ion-android-arrow-up" />
                </div>
              </button>
            </div>
          ) : (
            <div className={styles.text}>Még nem adtál hozzá kockát</div>
          )}
        </div>
      </div>
      <Modal
        isOpen={widgetState === "modal"}
        hasCloseBtn={true}
        onClose={() => setWidgetState("open")}
        size="xl"
      >
        <div>
          <h2 className={styles.modalTitle}>Összesítés</h2>
          <table>
            <tbody>
              <tr>
                <th>Darab</th>
                <th>Kocka</th>
                <th>Egységár</th>
                <th>Ár</th>
              </tr>
              {brickDetails.map((brick, idx) => (
                <tr key={idx}>
                  <td>{brick.count}</td>
                  <td>{brick.name}</td>
                  <td>{brick.basePrice}</td>
                  <td>{brick.price}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={styles.totalRow}>
                <td colSpan={3}>Végösszeg</td>
                <td>{totalPrice}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Modal>
    </div>
  );
}

function getFormattedBricks(brickCounts) {
  return Object.entries(brickCounts)
    .filter(([name, count]) => count > 0)
    .map(([name, count]) => `${count}db ${formatName(name)}`);
}

function getBrickDetails(brickCounts, priceList) {
  return Object.entries(brickCounts)
    .filter(([name, count]) => count > 0)
    .map(([name, count]) => {
      return {
        name: formatName(name),
        count,
        basePrice: formatPrice(priceList[name]),
        price: formatPrice(getPriceOfBrickType(name, count, priceList)),
      };
    });
}

function countBricks(bricks) {
  const brickNameCounts = bricks.reduce((acc, curr) => {
    const name = curr.name;
    if (!acc[name]) {
      acc[name] = 0;
    }
    acc[name] += 1;
    return acc;
  }, {});
  return brickNameCounts;
}

function formatName(name) {
  const [length, width, type] = name.split("_");
  return `${length}cm x ${width}cm${formatType(type)}`;
}

function formatType(type) {
  return type === "ST" ? "" : " (záró sor)";
}

function getTotalPrice(counts, priceList) {
  return formatPrice(
    Object.entries(counts).reduce((currentSum, [name, count]) => {
      return currentSum + getPriceOfBrickType(name, count, priceList);
    }, 0)
  );
}

function getPriceOfBrickType(brickName, count, priceList) {
  return count * priceList[brickName];
}

function formatPrice(price) {
  return new Intl.NumberFormat("hu-HU", {
    style: "currency",
    currency: "HUF",
    maximumFractionDigits: 0,
  }).format(price);
}
