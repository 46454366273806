import React from "react";

import * as styles from "../styles/components/top-bar.less";

/**
 *
 * @param {{ text?: string; icon?: string; active?: boolean; onClick: () => any; className?: string }} param0
 * @returns
 */
const TopBarButton = ({ text, icon, active, onClick, className }) => (
  <div
    className={`${active ? styles.active : styles.button} ${className || ""}`}
    onClick={onClick}
  >
    <div className={styles.icon}>
      <i className={`ion-${icon}`} />
    </div>
    <div className={styles.text}>{text}</div>
  </div>
);

export default TopBarButton;
