// @ts-check

import { createSlice } from "@reduxjs/toolkit";
import { colors, bricks } from "../utils/constants";

const builderSlice = createSlice({
  name: "builder",
  initialState: {
    loading: true,
    loadError: null,
    mode: "build",
    grid: true,
    color: colors[0],
    brick: bricks[0],
    rotation: 0,
    priceList: {},
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoadError(state, action) {
      state.loadError = action.payload;
    },
    setMode(state, action) {
      const { mode } = action.payload;
      state.mode = mode;
    },
    toggleGrid(state) {
      state.grid = !state.grid;
    },
    setBrick(state, action) {
      const { brick } = action.payload;
      state.brick = brick;
    },
    toggleRotation(state) {
      // What we want is to notify the Scene component that the rotation has changed
      // so that it can call the .rotate() method on the brick which
      // will correctly set the x and y coordinates of the brick
      // It's a bit hacky but it works
      state.rotation += 1;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
  },
  selectors: {
    getLoading(state) {
      return state.loading;
    },
    getLoadError(state) {
      return state.loadError;
    },
    getMode(state) {
      return state.mode;
    },
    getColor(state) {
      return state.color;
    },
    getIsGridVisible(state) {
      return state.grid;
    },
    getBrickDimensions(state) {
      return state.brick;
    },
    getRotation(state) {
      return state.rotation;
    },
    getPriceList(state) {
      return state.priceList;
    },
  },
});

export const {
  setLoading,
  setLoadError,
  setMode,
  toggleGrid,
  setBrick,
  toggleRotation,
  setPriceList,
} = builderSlice.actions;

export const {
  getLoading,
  getLoadError,
  getMode,
  getColor,
  getIsGridVisible,
  getBrickDimensions,
  getRotation,
  getPriceList,
} = builderSlice.selectors;

export default builderSlice.reducer;
