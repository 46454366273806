// extracted by mini-css-extract-plugin
export var detailsButton = "stats__detailsButton--dHJjO";
export var detailsIcon = "stats__detailsIcon--SgXzF";
export var hidden = "stats__hidden--JUz9l";
export var icon = "stats__icon--Oysa9";
export var iconWrapper = "stats__iconWrapper--cPZK0";
export var line = "stats__line--k31PL";
export var modalTitle = "stats__modalTitle--ghpkU";
export var stats = "stats__stats--FEX1u";
export var sumAllPrice = "stats__sumAllPrice--Gtu54 stats__text--QXRL6";
export var text = "stats__text--QXRL6";
export var title = "stats__title--kEhkd";
export var totalRow = "stats__totalRow--ggDvi";