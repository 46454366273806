import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import Builder from "./containers/Builder";

import store from "./store";

import "styles/app";
import { HotkeyProvider } from "./components/HotkeyProvideer";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <HotkeyProvider>
      <Builder />
    </HotkeyProvider>
  </Provider>
);
