import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as THREE from "three";

export class Controls extends OrbitControls {
  constructor(...args) {
    super(...args);
  }
  init() {
    this.enableDamping = true;
    this.dampingFactor = 0.15;
    this.rotateSpeed = 0.7;
    this.maxPolarAngle = Math.PI / 2;
    this.minDistance = 200;
    this.maxDistance = 6500;
    this.maxTargetRadius = 4000;
    this.screenSpacePanning = false; // The camera pans only horizontally
    this.touches = {
      ONE: THREE.TOUCH.ROTATE,
      TWO: THREE.TOUCH.DOLLY_PAN,
    };
  }
}
