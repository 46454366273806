import React from "react";
import FormButton from "./FormButton";

// @ts-ignore
import * as styles from "../styles/components/save-scene.less";

export default function SelectSaveOrEditScene({
  onNewSelected,
  onEditSelected,
}) {
  return (
    <div className={styles.saveScene}>
      <p>Kérjük válassza ki milyen módon szeretné menteni tervrajzát!</p>
      <FormButton
        className={styles.button}
        type="button"
        onClick={() => onEditSelected()}
        disabled={false}
        loading={false}
      >
        Meglévő felülírása
      </FormButton>

      <FormButton
        className={styles.button}
        type="button"
        onClick={() => onNewSelected()}
        disabled={false}
        loading={false}
      >
        Új mentése
      </FormButton>
    </div>
  );
}
