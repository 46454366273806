// @ts-check

import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    utilsOpen: false,
    isSceneActive: true,
    sceneId: "",
  },
  reducers: {
    setIsSceneActive(state, action) {
      state.isSceneActive = action.payload;
    },
    toggleUtils(state) {
      state.utilsOpen = !state.utilsOpen;
    },
    setSceneId(state, action) {
      state.sceneId = action.payload;
    },
  },
  selectors: {
    getAreUtilsOpen(state) {
      return state.utilsOpen;
    },
    getIsSceneActive(state) {
      return state.isSceneActive;
    },
    getSceneId(state) {
      return state.sceneId;
    },
  },
});

export const { toggleUtils, setIsSceneActive, setSceneId } = uiSlice.actions;

export const { getAreUtilsOpen, getIsSceneActive, getSceneId } =
  uiSlice.selectors;

export default uiSlice.reducer;
