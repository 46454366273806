// extracted by mini-css-extract-plugin
export var close = "help__close--P90tM";
export var content = "help__content--TdW4h";
export var explanationSection = "help__explanationSection--cl0Lg";
export var help = "help__help--qVS3_";
export var icon = "help__icon--EcSHS";
export var imgCam = "help__imgCam--qM4_e";
export var inversed = "help__inversed--YUZWV help__triggerButton--H6M8I";
export var section = "help__section--csKlV";
export var subtitle = "help__subtitle--tju3Z";
export var title = "help__title--fmyii";
export var triggerButton = "help__triggerButton--H6M8I";