// @ts-check

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useClickOutside } from "../hooks/useClickOutside";
import { useKeyUp } from "../hooks/useKeyUp";

import { getMode, setMode } from "../features/builderSlice";

import { MODES } from "../utils/constants";

// @ts-ignore
import * as styles from "../styles/components/mode-selector.less";

export default function ModeSelectorModal({ setOpen }) {
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));
  useKeyUp(27, () => setOpen(false));

  const mode = useSelector(getMode);
  const dispatch = useDispatch();
  const handleSetMode = (payload) => dispatch(setMode(payload));

  function handleClick(modeName) {
    handleSetMode({ mode: modeName });
    setOpen(false);
  }

  return (
    <div className={styles.modeSelectorModal} ref={ref}>
      {MODES.map(({ modeName, icon, displayName }) => (
        <div
          key={modeName}
          className={modeName === mode ? styles.selectedMode : styles.modeElem}
          onClick={() => {
            handleClick(modeName);
          }}
        >
          <div>
            <i className={`ion-${icon}`} />
          </div>
          <div>{displayName}</div>
        </div>
      ))}
    </div>
  );
}
