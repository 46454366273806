// @ts-check

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import { bricks } from "../utils/constants";
import {
  displayNameFromDimensions,
  getBrickIconFromDimensions,
} from "../utils";

import { getBrickDimensions, setBrick } from "../features/builderSlice";

import { useClickOutside } from "../hooks/useClickOutside";
import { useKeyUp } from "../hooks/useKeyUp";

// @ts-ignore
import * as styles from "../styles/components/brick-picker";

export default function BrickPickerModal({ setOpen }) {
  const dispatch = useDispatch();
  const handleSetBrick = (payload) => dispatch(setBrick(payload));
  const selectedSize = useSelector(getBrickDimensions);

  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));
  useKeyUp(27, () => setOpen(false));

  function handleBrickSelection(brick) {
    handleSetBrick({ brick: brick });
    setTimeout(() => setOpen(false), 200);
  }

  return (
    <div className={styles.picker} ref={ref}>
      {bricks.map((brick, i) => (
        <div key={i} className={styles.brickExample}>
          <div
            className={
              isEqual(selectedSize, brick) ? styles.selected : styles.brickThumb
            }
            onClick={() => handleBrickSelection(brick)}
          >
            {getBrickIconFromDimensions(brick)}
          </div>
          <div className={styles.label}>{displayNameFromDimensions(brick)}</div>
        </div>
      ))}
    </div>
  );
}
