import React from "react";
import Logo from "../../static/betonkocka-logo-eredeti.svg";

// @ts-ignore
import * as styles from "../styles/components/image-logo.less";

export default function ImageLogo() {
  return (
    <div className={styles.imgLogoContainer}>
      <img className={styles.imgLogo} src={Logo} alt="Betonkocka Tervező" />
    </div>
  );
}
