// @ts-check

import debug from "debug";

const log = debug("app:api");

class RequestError extends Error {
  /**
   * @param {Response} response
   * @param {any=} requestBody
   * @param {any=} responseBody
   */
  constructor(response, requestBody, responseBody) {
    super(response.statusText);

    this.name = "RequestError";

    this.response = response;
    this.requestBody = requestBody;
    this.responseBody = responseBody;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.response.statusText,
      status: this.response.status,
      url: this.response.url,
      requestBody: this.requestBody,
      responseBody: this.responseBody,
    };
  }
}

/**
 * @param {string} email
 * @param {any} data
 * @returns {Promise<{ id: string; }>}
 */
export function createScene(email, data) {
  const body = { email, data };

  log("createScene", body);

  return fetch("/api/scenes", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return assertResponseOk(response, body);
    })
    .then((response) => {
      return response.json();
    });
}

/**
 * @param {string} id
 * @returns {Promise<{ id: string; data: any; }>}
 */
export function getScene(id) {
  log("getScene", id);
  return fetch(`/api/scenes/${id}`)
    .then((response) => {
      return assertResponseOk(response);
    })
    .then((response) => {
      return response.json();
    });
}

/**
 * @returns {Promise<{ priceList: any; }>}
 */
export function getPrices() {
  log("getPrices");
  return fetch(`/api/prices`)
    .then((response) => {
      return assertResponseOk(response);
    })
    .then((response) => {
      return response.json();
    });
}

/**
 *
 * @param {string} id
 * @param {any} data
 * @returns {Promise<{ id: string; data: any; }>}
 */
export function updateScene(id, data) {
  log("updateScene", id, data);
  const body = { data };

  return fetch(`/api/scenes/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return assertResponseOk(response, body);
    })
    .then((response) => {
      return response.json();
    });
}

/**
 * @param {Response} response
 * @param {any=} requestBody
 * @returns {Promise<Response>}
 */
function assertResponseOk(response, requestBody) {
  if (response.ok) {
    return Promise.resolve(response);
  }
  return new Promise((resolve, reject) => {
    return parseBody(response).then((parsedBody) => {
      log("request error", response, parsedBody);
      reject(new RequestError(response, requestBody, parsedBody));
    });
  });
}

/**
 * @param {Response} response
 * @returns
 */
function parseBody(response) {
  return Promise.resolve()
    .then(() => {
      if (response.headers.get("Content-Type")?.includes("application/json")) {
        return response.json();
      }
      return response.text();
    })
    .catch(() => undefined);
}
