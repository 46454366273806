// @ts-check
import * as THREE from "three";

import { OrthographicCamera } from "./Camera";

export class Light extends THREE.DirectionalLight {
  constructor() {
    // super(0xff8c85);
    super(0xffffff);
  }
  init() {
    this.position.set(-2000, 3000, -1000);
    this.intensity = 3;
    this.castShadow = true;
    this.shadow.bias = -0.0000022;
    this.shadow.mapSize.width = 5000;
    this.shadow.mapSize.height = 5000;
    this.shadow.camera.near = 200;
    this.shadow.camera.far = 8000;
    this.shadow.camera.left = -4000;
    this.shadow.camera.right = 4000;
    this.shadow.camera.top = 3250;
    this.shadow.camera.bottom = -3200;
    this.penumbra = 0.5;
    this.decay = 2;
    // this.shadow.camera.far = 100000;
    // this.shadow.camera.fov = 120;
  }
}

export class AmbientLight extends THREE.AmbientLight {
  constructor(...args) {
    super(...args);
  }
}
