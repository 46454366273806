// @ts-check

import React, { useState } from "react";
import { useSelector } from "react-redux";

import TopBarButton from "./TopBarButton";
import ModeSelectorModal from "./ModeSelectorModal";

import { getMode } from "../features/builderSlice";

import { MODES } from "../utils/constants";

// @ts-ignore
import * as styles from "../styles/components/mode-selector.less";

export default function ModeSelector({ className }) {
  const mode = useSelector(getMode);

  const [open, setOpen] = useState(false);

  const selectedMode = MODES.find(({ modeName }) => modeName === mode);

  return (
    <div className={`${styles.modeSelector} ${className || ""}`}>
      <TopBarButton
        active={false}
        onClick={() => setOpen(!open)}
        icon={selectedMode?.icon}
        text={"Mód: " + selectedMode?.displayName}
      />
      {open && <ModeSelectorModal setOpen={setOpen} />}
    </div>
  );
}
