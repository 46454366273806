import { v4 } from "uuid";
import * as THREE from "three";

import { mergeMeshes, degToRad } from "utils/threejs";
import { CSSToHex, shadeColor, getMeasurementsFromDimensions } from "utils";
import { base } from "utils/constants";
import FacetedBrick from "./FacetedBrick";

const knobSize = base / 2;

export default class Brick extends THREE.Mesh {
  constructor({
    intersect,
    color,
    dimensions,
    rotation,
    translation,
    customId,
  }) {
    const cubeMaterial = new THREE.MeshStandardMaterial({
      color: CSSToHex(color),
      // specular: CSSToHex(shadeColor(color, -20)),
      // shininess: 5,
      flatShading: true,
      metalness: 0.2,
      roughness: 0.5,
    });
    const { height, width, depth } = getMeasurementsFromDimensions(dimensions);
    const props = createMesh(cubeMaterial, width, height, depth, dimensions);
    super(...props);
    const evenWidth = dimensions.x % 2 === 0;
    const evenDepth = dimensions.z % 2 === 0;

    this.height = height;
    this.width = width;
    this.depth = depth;
    this.position.copy(intersect.point).add(intersect.face.normal);
    this.position
      .divide(new THREE.Vector3(base, height, base))
      .floor()
      .multiply(new THREE.Vector3(base, height, base))
      .add(
        new THREE.Vector3(
          evenWidth ? base : base / 2,
          height / 2,
          evenDepth ? base : base / 2
        )
      );
    this.name = `${width}_${depth}_${dimensions.top ? "T" : "ST"}`;
    this.rotation.y = rotation;
    this.geometry.translate(translation, 0, translation);
    this.castShadow = true;
    this.receiveShadow = true;
    this.customId = customId || v4();
    this.defaultColor = cubeMaterial.color;

    this._intersect = intersect;
    this._color = color;
    this._dimensions = dimensions;
    this._rotation = rotation;
    this._translation = translation;
  }

  updateColor(color) {
    this.material.setValues({ color: CSSToHex(color) });
    this.defaultColor = this.material.color;
    this._color = color;
  }

  toPOJO() {
    return JSON.parse(
      JSON.stringify({
        intersect: {
          point: this._intersect.point,
          face: { normal: this._intersect.face.normal },
        },
        color: this._color,
        dimensions: this._dimensions,
        rotation: this._rotation,
        translation: this._translation,
        customId: this.customId,
        name: this.name,
      })
    );
  }

  // rotate(rotation) {
  //   this.rotateY(degToRad(rotation));
  // }
}

function createMesh(material, width, height, depth, dimensions) {
  let meshes = [];

  //Simple bricks
  //const cubeGeo = new THREE.BoxGeometry(width - 0.1, height - 0.1, depth - 0.1);

  //Cut off edge bricks
  const cubeGeo = new FacetedBrick(
    width - 0.1,
    height - 0.1,
    depth - 0.1,
    base / 10
  );

  meshes.push(cubeGeo);

  if (!dimensions.top) {
    const cylinderGeo = new THREE.CylinderGeometry(
      knobSize * 0.3,
      knobSize,
      knobSize / 1.5,
      4
    );

    for (var i = 0; i < dimensions.x; i++) {
      for (var j = 0; j < dimensions.z; j++) {
        const cylinder = cylinderGeo.clone();
        const x = base * i - ((dimensions.x - 1) * base) / 2;
        const y = (base * 2) / 1.5;
        const z = base * j - ((dimensions.z - 1) * base) / 2;
        cylinder.rotateY(Math.PI / 4);
        cylinder.translate(x, y, z);
        meshes.push(cylinder);
      }
    }
  }
  const brickGeometry = mergeMeshes(meshes);
  return [brickGeometry, material];
}
