import React from "react";
import * as styles from "../styles/components/loader.less";

/**
 * @typedef {Object} LoaderProps
 * @property {string=} className
 * @property {('sm' | 'md' | 'lg')=} size
 */

/**
 * @param {LoaderProps} props
 * @returns
 */
export function Loader({ size, className }) {
  const classes = [styles.loader, className];
  if (size === "sm") {
    classes.push(styles.loaderSm);
  } else if (size === "lg") {
    classes.push(styles.loaderMd);
  } else {
    classes.push(styles.loaderLg);
  }

  return (
    <div className={classes.join(" ")}>
      <span className="sr-only">Töltés...</span>
    </div>
  );
}
