import React from "react";

export const SimpleBrick = ({ color }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 36 26">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-16.000000, -245.000000)"
          fill={color || "#000000"}
        >
          <path
            d="M16,248 L52,248 L52,271 L16,271 L16,248 Z M19,245 L30,245 L30,248 L19,248 L19,245 Z M37,245 L48,245 L48,248 L37,248 L37,245 Z"
            id="Brick"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const B2x1 = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 63.584 96.813"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m-.01 27.84 29.73.127v49.05L-.01 76.89z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m22.111 47.676 41.53-28.66v49.15l-41.53 28.66Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M63.725 19.053 22.16 47.825-.097 27.889 41.75.032Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
    </svg>
  );
};

export const B4x1 = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 121.557 137.559"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m-.01 66.785 32.82.009v49.05l-32.82-.008z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m24.375 88.63 97.102-67.131v49.15l-97.102 67.13Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M121.458 21.47 24.35 88.673-.097 66.834 97.29.546Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(-16.596 -43.8) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(-16.596 -43.8) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(-16.596 -43.8) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(7.173 -60.47) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(7.173 -60.47) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(7.173 -60.47) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(30.56 -76.118) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(30.56 -76.118) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(30.56 -76.118) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(53.84 -91.659) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(53.84 -91.659) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(53.84 -91.659) scale(.91675)"
      />
    </svg>
  );
};

export const B6x1 = () => {
  return (
    <svg height="100%" viewBox="0 0 180 182" xmlns="http://www.w3.org/2000/svg">
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.278847,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m0 105.986 33.455.054v53.506L0 159.492Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.35444,
          }}
          transform="skewY(-34.635) scale(.8228 1)"
          d="M30.29 145.57h188.089v53.614H30.29z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.814056,
          }}
          d="M179.697 21.495 24.895 128.372-.097 106.048 155 .03Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="rotate(1.961 507.02 -399.864)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="rotate(1.961 507.02 -399.864)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="rotate(1.961 507.02 -399.864)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(7.206 -33.747)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(7.206 -33.747)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(7.206 -33.747)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(33.298 -50.886)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(33.298 -50.886)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(33.298 -50.886)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(57.854 -68.024)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(57.854 -68.024)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(57.854 -68.024)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(106.456 -102.045)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(106.456 -102.045)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(106.456 -102.045)"
      />
    </svg>
  );
};

export const B2x2 = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 82.788 114.011"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M-.011 27.839h55.658V76.89H-.011z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m41.264 64.783 41.53-28.66v49.15l-41.53 28.66Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M82.808 36.123 41.24 64.895-.097 27.889 41.75.032Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="rotate(2.196 2180.367 -423.438) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="rotate(2.196 1742.595 81.932) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="rotate(2.196 1742.595 81.932) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="rotate(2.196 1742.595 81.932) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="matrix(.91617 .03241 -.03241 .91617 4.94 -96.627)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="rotate(2.026 2261.09 639.839) scale(.91674)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="rotate(2.026 2261.09 639.839) scale(.91674)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="rotate(2.026 2261.09 639.839) scale(.91674)"
      />
    </svg>
  );
};

export const B4x2 = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 138.353 152.905"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M-.011 66.785h55.658v49.051H-.011z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m41.264 103.728 97.102-67.13v49.15l-97.102 67.13Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M138.348 36.638 41.24 103.84-.097 66.834 97.29.546Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(-16.852 -45.845) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(-16.852 -45.845) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(-16.852 -45.845) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(2.834 -29.442) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(2.834 -29.442) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(2.834 -29.442) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(5.895 -62.26) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(5.895 -62.26) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(5.895 -62.26) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(25.581 -45.857) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(25.581 -45.857) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(25.581 -45.857) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(30.049 -78.676) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(30.049 -78.676) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(30.049 -78.676) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(49.735 -62.273) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(49.735 -62.273) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(49.735 -62.273) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(52.56 -93.45) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(52.56 -93.45) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(52.56 -93.45) scale(.91675)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(72.246 -77.047) scale(.91675)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(72.246 -77.047) scale(.91675)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(72.246 -77.047) scale(.91675)"
      />
    </svg>
  );
};

export const B6x2 = () => {
  return (
    <svg height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.278847,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M0 105.986h60.712v53.505H0z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.35444,
          }}
          transform="skewY(-34.635) scale(.8228 1)"
          d="M54.717 177.391h188.089v53.614H54.717z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.814056,
          }}
          d="M199.785 39.399 44.995 146.415-.097 106.048 155 .03Z"
        />
      </g>
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(-18.374 -16.865)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(-18.374 -16.865)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(-18.374 -16.865)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(3.1 1.028)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(3.1 1.028)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(3.1 1.028)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(6.439 -34.77)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(6.439 -34.77)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(6.439 -34.77)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(27.913 -16.877)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(27.913 -16.877)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(27.913 -16.877)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(32.786 -52.676)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(32.786 -52.676)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(32.786 -52.676)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(54.26 -34.783)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(54.26 -34.783)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(54.26 -34.783)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(57.343 -68.792)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(57.343 -68.792)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(57.343 -68.792)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(78.817 -50.899)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(78.817 -50.899)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(78.817 -50.899)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(81.9 -85.419)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(103.374 -67.526)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(103.374 -67.526)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(103.374 -67.526)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(106.456 -102.045)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(106.456 -102.045)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(106.456 -102.045)"
      />
      <path
        style={{
          fill: "#868585",
          strokeWidth: 0.264583,
        }}
        d="m45.02 120.097 8.678-5.416 8.553 7.785-17.215 12.55z"
        transform="translate(127.93 -84.152)"
      />
      <path
        style={{
          fill: "#bdbaba",
          fillOpacity: 1,
          strokeWidth: 0.264583,
        }}
        d="m45.062 120.1-7.93-5.356-8.078 7.179 15.992 13.096z"
        transform="translate(127.93 -84.152)"
      />
      <path
        style={{
          fill: "#e3e0e0",
          strokeWidth: 0.264583,
        }}
        d="m37.144 114.75 7.942-4.904 8.636 4.838-8.7 5.424z"
        transform="translate(127.93 -84.152)"
      />
    </svg>
  );
};

export const B2x1xTop = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 63.584 96.813"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m-.01 27.84 29.73.127v49.05L-.01 76.89z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m22.111 47.676 41.53-28.66v49.15l-41.53 28.66Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M63.725 19.053 22.16 47.825-.097 27.889 41.75.032Z"
        />
      </g>
    </svg>
  );
};

export const B4x1xTop = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 121.557 137.559"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m-.01 66.785 32.82.009v49.05l-32.82-.008z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m24.375 88.63 97.102-67.131v49.15l-97.102 67.13Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M121.458 21.47 24.35 88.673-.097 66.834 97.29.546Z"
        />
      </g>
    </svg>
  );
};

export const B6x1xTop = () => {
  return (
    <svg height="100%" viewBox="0 0 180 182" xmlns="http://www.w3.org/2000/svg">
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.278847,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="m0 105.986 33.455.054v53.506L0 159.492Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.35444,
          }}
          transform="skewY(-34.635) scale(.8228 1)"
          d="M30.29 145.57h188.089v53.614H30.29z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.814056,
          }}
          d="M179.697 21.495 24.895 128.372-.097 106.048 155 .03Z"
        />
      </g>
    </svg>
  );
};

export const B2x2xTop = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 82.788 114.011"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M-.011 27.839h55.658V76.89H-.011z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m41.264 64.783 41.53-28.66v49.15l-41.53 28.66Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M82.808 36.123 41.24 64.895-.097 27.889 41.75.032Z"
        />
      </g>
    </svg>
  );
};

export const B4x2xTop = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 138.353 152.905"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.255632,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M-.011 66.785h55.658v49.051H-.011z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.294739,
          }}
          d="m41.264 103.728 97.102-67.13v49.15l-97.102 67.13Z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.746283,
          }}
          d="M138.348 36.638 41.24 103.84-.097 66.834 97.29.546Z"
        />
      </g>
    </svg>
  );
};

export const B6x2xTop = () => {
  return (
    <svg height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#bdbaba",
            strokeWidth: 0.278847,
          }}
          transform="skewY(41.85) scale(.7449 1)"
          d="M0 105.986h60.712v53.505H0z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#868585",
            strokeWidth: 0.35444,
          }}
          transform="skewY(-34.635) scale(.8228 1)"
          d="M54.717 177.391h188.089v53.614H54.717z"
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            display: "inline",
            fill: "#e0dede",
            strokeWidth: 0.814056,
          }}
          d="M199.785 39.399 44.995 146.415-.097 106.048 155 .03Z"
        />
      </g>
    </svg>
  );
};
