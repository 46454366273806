// @ts-check

import React from "react";
import { Loader } from "./Loader";

// @ts-ignore
import * as styles from "../styles/components/form-button.less";

/**
 * @typedef {Object} FormButtonProps
 * @property {string=} className
 * @property {boolean=} disabled
 * @property {boolean} loading
 * @property {() => void | Promise<void>} onClick
 * @property {'submit' | 'button'=} type
 * @property {React.ReactNode=} children
 */

/**
 * @param {FormButtonProps} props
 * @returns
 */
export default function FormButton({
  type,
  className,
  onClick,
  disabled,
  children,
  loading,
}) {
  return (
    <button
      className={`${styles.formButton} ${className || ""}`}
      type={type || "submit"}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? <Loader size="sm" /> : children}
    </button>
  );
}
