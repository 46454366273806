// @ts-check

import React, { useState } from "react";
import { useSelector } from "react-redux";

import SaveSceneForm from "./SaveSceneForm";
import EditScene from "./EditScene";
import SelectSaveOrEditScene from "./SelectSaveOrEditScene";
import SaveFormSuccess from "./SaveFormSucces";

import { getSceneId } from "../features/uiSlice";

export default function SaveSceneModalContent() {
  const sceneId = useSelector(getSceneId);
  const [mode, setMode] = useState(
    /** @type {'select' | 'create' | 'edit' | 'success'} */ (
      sceneId ? "select" : "create"
    )
  );

  function component() {
    if (mode === "create") {
      return <SaveSceneForm onSuccess={() => setMode("success")} />;
    }
    if (mode === "edit") {
      return <EditScene onSuccess={() => setMode("success")} />;
    }
    if (mode === "select") {
      return (
        <SelectSaveOrEditScene
          onNewSelected={() => setMode("create")}
          onEditSelected={() => setMode("edit")}
        />
      );
    }
    return <SaveFormSuccess />;
  }

  return component();
}
