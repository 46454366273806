// @ts-check

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { getAreUtilsOpen } from "../features/uiSlice";

import Modal from "./Modal";
import Kbd from "./Kbd";
import { MODES, MODE_NAMES } from "../utils/constants";
import { isMac } from "../utils";

// @ts-ignore
import Rotate from "../../static/rotate.png";
// @ts-ignore
import Zoom from "../../static/zoom.png";
// @ts-ignore
import Move from "../../static/move.png";

// @ts-ignore
import * as styles from "../styles/components/help";

const buildMode = MODES.find(({ modeName }) => modeName === MODE_NAMES.BUILD);
const deleteMode = MODES.find(
  ({ modeName }) => modeName === MODE_NAMES.DELETION
);
const modifierKey = isMac ? "Cmd" : "Ctrl";
const redoKeys = isMac ? ["Cmd", "Shift", "Z"] : ["Ctrl", "Y"];

export default function Help() {
  const [open, setOpen] = useState(
    !localStorage.getItem("firstLoad") ? true : false
  );
  const inversed = useSelector(getAreUtilsOpen);

  useEffect(() => {
    if (!localStorage.getItem("firstLoad"))
      localStorage.setItem("firstLoad", "false");
  }, []);

  return (
    <div className={styles.help}>
      <button
        className={inversed ? styles.inversed : styles.triggerButton}
        onClick={() => setOpen(!open)}
        type="button"
      >
        <i className="ion-information-circled" />
        <span>Segítség</span>
      </button>
      <Modal
        isOpen={open}
        hasCloseBtn={true}
        onClose={() => setOpen(false)}
        size="xl"
      >
        <div className={styles.content}>
          <h1 className={styles.title}>Betonkocka építő</h1>
          <p>
            Tervezze meg épületét a{" "}
            <a href="https://betonaru.hu">Betonáru.hu</a> által forgalmazott{" "}
            <a href="https://betonkocka.hu">Mobil támfal (Betonkocka)</a>{" "}
            elemekből. Készítse el épületének méretarányos tervét, hogy
            megtudja, pontosan hány betonkockára is van szüksége.
          </p>
          <div className={styles.explanationSection}>
            <div>
              <h2 className={styles.subtitle}>Az alkalmazás működése</h2>
              <div className={styles.section}>
                <div className={styles.icon}>
                  <i className={`ion-${buildMode?.icon}`} />
                </div>
                <div style={{ flex: 1 }}>
                  Az ÉPÍTÉS módban{" "}
                  {isTouchscreen() ? "koppintással" : "bal kattintással"} tud új
                  betonkockát hozzáadni.
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.icon}>
                  <i className={`ion-${deleteMode?.icon}`} />
                </div>
                <div style={{ flex: 1 }}>
                  A törlés módban már felrakott betonkockákat tudja
                  eltávolítani.
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.icon}>
                  <i className={`ion-refresh`} />
                </div>
                <div style={{ flex: 1 }}>
                  A forgatás gombbal tudja forgatni a betonkockákat.
                </div>
              </div>
            </div>
            {isTouchscreen() ? (
              <div>
                <h2 className={styles.subtitle}>Kamera vezérlése</h2>
                <div className={styles.section}>
                  <img
                    className={styles.imgCam}
                    src={Rotate}
                    alt="Kamera forgatása"
                  />
                  <div style={{ flex: 1 }}>
                    Forgatás egy ujjas érintéssel, majd mozgatással.
                  </div>
                </div>
                <div className={styles.section}>
                  <img className={styles.imgCam} src={Zoom} alt="Kamera zoom" />
                  <div style={{ flex: 1 }}>
                    Közelítés és távolítás az ujjak össze-, illetve
                    széthúzásával.
                  </div>
                </div>
                <div className={styles.section}>
                  <img
                    className={styles.imgCam}
                    src={Move}
                    alt="Kamera mozgatása"
                  />
                  <div style={{ flex: 1 }}>
                    Mozgatás két ujjas érintéssel, majd mozgatással.
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h2 className={styles.subtitle}>Billentyűparancsok</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Kbd keys={["É", "E"]} separator="vagy" />
                      </td>
                      <td>Építés</td>
                    </tr>
                    <tr>
                      <td>
                        <Kbd keys={["T"]} />
                      </td>
                      <td>Törlés</td>
                    </tr>
                    <tr>
                      <td>
                        <Kbd keys={["F"]} />
                      </td>
                      <td>Forgatás</td>
                    </tr>
                    <tr>
                      <td>
                        <Kbd keys={[modifierKey, "Z"]} />
                      </td>
                      <td>Visszavonás</td>
                    </tr>
                    <tr>
                      <td>
                        <Kbd keys={redoKeys} />
                      </td>
                      <td>Újra</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
function isTouchscreen() {
  return window.matchMedia("(pointer: coarse)").matches;
}
