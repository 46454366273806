import React from "react";
import { base } from "./constants";
import * as Icons from "../components/Icons";
import * as THREE from "three";

export function CSSToHex(cssColor) {
  return parseInt(`0x${cssColor.substring(1)}`, 16);
}

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function getMeasurementsFromDimensions({ x, y, z }) {
  return {
    width: base * x,
    height: base * y || (base * 4) / 1.5,
    depth: base * z,
  };
}

export function displayNameFromDimensions(dimensions) {
  const { width, height, depth } = getMeasurementsFromDimensions(dimensions);
  const brickName = `${width}x${depth}x${height}`;
  return brickName;
}

export function getBrickIconFromDimensions(dimensions) {
  const Icon =
    Icons[`B${dimensions.x}x${dimensions.z}${dimensions.top ? "xTop" : ""}`];
  return <Icon />;
}

export function makeVectorsFromSegments(meshBoundingBox, action) {
  const segmentsToCheck = [];
  let y;
  if (action === "create") y = meshBoundingBox.min.y;
  else if (action === "delete") y = meshBoundingBox.max.y;
  let inspectPositionX = meshBoundingBox.min.x + base / 2;
  for (
    inspectPositionX;
    inspectPositionX < meshBoundingBox.max.x;
    inspectPositionX += base
  ) {
    let inspectPositionZ = meshBoundingBox.min.z + base / 2;
    for (
      inspectPositionZ;
      inspectPositionZ < meshBoundingBox.max.z;
      inspectPositionZ += base
    ) {
      segmentsToCheck.push(
        new THREE.Vector3(inspectPositionX, y, inspectPositionZ)
      );
    }
  }

  return segmentsToCheck;
}

export const isMac = navigator.userAgent.includes("Macintosh");
