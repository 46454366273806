// @ts-check

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ActionCreators } from "redux-undo";

import TopBarButton from "./TopBarButton";
import BrickPicker from "./BrickPicker";
import SaveScene from "./SaveScene";
import ModeSelector from "./ModeSelector";
import ResetScene from "./ResetScene";

import { toggleRotation } from "../features/builderSlice";

// @ts-ignore
import * as styles from "../styles/components/top-bar.less";
import ImageLogo from "./ImageLogo";

export default function TopBar() {
  const dispatch = useDispatch();
  const handleToggleRotation = () => dispatch(toggleRotation());

  const [isMobileSlideOpen, setIsMobileSlideOpen] = useState(false);

  return (
    <div
      className={
        isMobileSlideOpen ? styles.topBarMobileSlideOpen : styles.topBar
      }
    >
      <button
        type="button"
        className={styles.mobileSlideTrigger}
        onClick={() => setIsMobileSlideOpen(!isMobileSlideOpen)}
        title="Mobil menü lenyitása"
      >
        <i className="ion-chevron-down" />
      </button>
      <ImageLogo />
      <div className={styles.buttonSection}>
        <BrickPicker className={styles.buttonContainer} />
        <ModeSelector className={styles.buttonContainer} />
        <div className={styles.buttonContainer}>
          <TopBarButton
            active={false}
            onClick={() => handleToggleRotation()}
            icon="refresh"
            text="Forgatás"
          />
        </div>
        <div className={styles.buttonContainer}>
          <TopBarButton
            active={false}
            onClick={() => dispatch(ActionCreators.undo())}
            icon="arrow-left-c"
            text="Vissza"
          />
        </div>
        <div className={styles.buttonContainer}>
          <TopBarButton
            active={false}
            onClick={() => dispatch(ActionCreators.redo())}
            icon="arrow-right-c"
            text="Újra"
          />
        </div>
        <SaveScene className={styles.buttonContainer} />
        <ResetScene className={styles.buttonContainer} />
      </div>
    </div>
  );
}
