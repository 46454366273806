import React, { useState } from "react";

import { useDispatch } from "react-redux";

import Modal from "./Modal";
import TopBarButton from "./TopBarButton";
import FormButton from "./FormButton";

import { setScene } from "../features/sceneSlice";
import { setIsSceneActive } from "../features/uiSlice";

import * as styles from "../styles/components/reset-scene.less";

export default function ResetScene({ className }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleSetIsSceneActive = (payload) =>
    dispatch(setIsSceneActive(payload));

  const handleSetScene = () => {
    dispatch(setScene({ bricks: [] }));
    handleClose();
  };

  function handleOpen() {
    handleSetIsSceneActive(false);
    setOpen(true);
  }

  function handleClose() {
    handleSetIsSceneActive(true);
    setOpen(false);
  }

  return (
    <div className={className}>
      <TopBarButton
        active={false}
        onClick={handleOpen}
        icon="trash-b"
        text="Minden törlése"
      />
      <Modal isOpen={open} hasCloseBtn={true} onClose={handleClose}>
        <div className={styles.modalContent}>
          <h2>Biztosan törölni szeretne mindent?</h2>
          <p>
            Ezzel elveszik az összes elem, amit eddig feltett a tervére. A
            művelet nem visszavonható!
          </p>
          <div className={styles.modalActions}>
            <FormButton onClick={() => handleSetScene()}>Törlés</FormButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}
