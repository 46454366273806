// @ts-check

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import TopBarButton from "./TopBarButton";
import Modal from "./Modal";
import SaveSceneModalContent from "./SaveSceneModalContent";

import { setIsSceneActive } from "../features/uiSlice";

export default function SaveScene({ className }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const handleSetIsSceneActive = (payload) =>
    dispatch(setIsSceneActive(payload));

  function handleOpen() {
    handleSetIsSceneActive(false);
    setOpen(true);
  }

  function handleClose() {
    handleSetIsSceneActive(true);
    setOpen(false);
  }

  return (
    <div className={className}>
      <TopBarButton
        active={false}
        onClick={handleOpen}
        icon="ios-download"
        text="Mentés"
      />
      <Modal isOpen={open} hasCloseBtn={true} onClose={handleClose} size="lg">
        <h1 style={{ textAlign: "center" }}>Terv mentése</h1>
        <SaveSceneModalContent />
      </Modal>
    </div>
  );
}
