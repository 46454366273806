// @ts-check

import { createSlice } from "@reduxjs/toolkit";

const sceneSlice = createSlice({
  name: "scene",
  initialState: {
    /** @type {any[]} */
    bricks: [],
  },
  reducers: {
    addBrick(state, action) {
      const { brick } = action.payload;
      state.bricks.push(brick);
    },
    removeBrick(state, action) {
      const { id } = action.payload;
      state.bricks = state.bricks.filter((b) => b.customId !== id);
    },
    updateBrick(state, action) {
      const { brick } = action.payload;
      const filteredBricks = state.bricks.filter(
        (b) => b.customId !== brick.customId
      );
      state.bricks = [...filteredBricks, brick];
    },
    resetScene(state) {
      state.bricks = [];
    },
    setScene(state, action) {
      const { bricks } = action.payload;
      state.bricks = bricks;
    },
  },
  selectors: {
    getBricks(state) {
      // @ts-ignore
      return state.present.bricks;
    },
  },
});

export const { getBricks } = sceneSlice.selectors;

export const { addBrick, removeBrick, updateBrick, resetScene, setScene } =
  sceneSlice.actions;

export default sceneSlice.reducer;
