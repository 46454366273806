// @ts-check

import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import debug from "debug";

import * as api from "../api";

import { getBricks } from "../features/sceneSlice";
import { setSceneId } from "../features/uiSlice";

import FormButton from "./FormButton";

// @ts-ignore
import * as styles from "../styles/components/save-scene-form.less";

const log = debug("app:components:save-scene-form");

export default function SaveSceneForm({ onSuccess }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(/** @type {null | string} */ (null));

  const bricks = useSelector(getBricks);
  const handleSetSceneId = (payload) => dispatch(setSceneId(payload));

  const emailInputRef = useRef(/** @type {HTMLInputElement | null} */ (null));

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      log("handleSubmit", { email });
      if (!email) {
        setError("Kérjük adjon meg egy email címet!");
        return;
      }

      if (!emailInputRef.current?.validity.valid) {
        setError("Kérjük adjon meg egy érvényes email címet!");
        return;
      }

      setLoading(true);
      setError(null);

      const { id } = await api.createScene(email, bricks);

      log("create scene successful", { id });

      const url = new URL(window.location.href);
      url.searchParams.set("id", id);
      window.history.pushState({}, "", url);
      handleSetSceneId(id);

      setLoading(false);
      setError(null);
      onSuccess();
    } catch (error) {
      log("create scene failed", { error, email, loading });
      setLoading(false);
      setError(error?.message);
    }
  };

  return (
    <form className={styles.saveSceneForm}>
      <div>
        <p>
          Kérjük adja meg e-mail címét, és elküldjük Önnek a tervrajza linkét!
        </p>
        <div>
          <input
            ref={emailInputRef}
            className={styles.input}
            type="email"
            placeholder="Email cím"
            value={email}
            onChange={handleChangeEmail}
          />
          {error && <div className={styles.error}>{error}</div>}
          <FormButton
            className={styles.button}
            onClick={handleSubmit}
            loading={loading}
          >
            Mentés
          </FormButton>
        </div>
      </div>
    </form>
  );
}
