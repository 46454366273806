// @ts-check

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import { setScene } from "../features/sceneSlice";
import { setSceneId } from "../features/uiSlice";
import {
  setLoadError,
  setLoading,
  setPriceList,
} from "../features/builderSlice";

import Scene from "../components/engine/Scene";
import TopBar from "../components/TopBar";
import Help from "../components/Help";
import Stats from "../components/Stats";
import Brick from "../components/engine/Brick";

import * as api from "../api";

// @ts-ignore
import * as styles from "../styles/containers/builder.less";

export default function Builder() {
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [pricesLoaded, setPricesLoaded] = useState(false);

  const dispatch = useDispatch();
  const handleSetLoading = (payload) => dispatch(setLoading(payload));
  const handleSetLoadError = (payload) => dispatch(setLoadError(payload));
  const handleSetPriceList = (payload) => dispatch(setPriceList(payload));
  const handleSetScene = (payload) => dispatch(setScene(payload));
  const handleSetSceneId = (payload) => dispatch(setSceneId(payload));

  //getPrices
  useEffect(() => {
    api
      .getPrices()
      .then((res) => {
        handleSetPriceList(res.priceList);
        setPricesLoaded(true);
      })
      .catch((error) => {
        handleSetLoadError(
          "Hiba történt az árak betöltése során. Kérjük próbálja újra!"
        );
        console.error("Error while loading prices", error);
      });
  }, []);

  //getScene
  useEffect(() => {
    if (window.location.search && window.location.search.length > 1) {
      const search = new URLSearchParams(window.location.search);
      const sceneId = search.get("id");
      if (sceneId) {
        api
          .getScene(sceneId)
          .then((res) => {
            handleSetScene({
              bricks: res.data.map((brick) => ({
                ...brick,
                customId: brick.customId || uuid(), // For backward compatibility as id was not always saved at the beginning
              })),
            });
            handleSetSceneId(sceneId);
            setSceneLoaded(true);
          })
          .catch((error) => {
            handleSetLoadError(
              "Hiba történt a tervének betöltése során. Kérjük próbálja újra!"
            );
            console.error("Error while loading scene", error);
          });
      }
    } else {
      setSceneLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (sceneLoaded && pricesLoaded) {
      handleSetLoading(false);
    }
  }, [sceneLoaded, pricesLoaded]);

  return (
    <div className={styles.builder}>
      <TopBar />
      <Scene />
      <Help />
      <Stats />
    </div>
  );
}
