import React from "react";

import * as styles from "../styles/components/save-scene-form.less";

export default function SaveFormSuccess() {
  return (
    <div className={styles.success}>
      <p>
        Ön sikeresen elmentette a tervét! A hozzá tartozó linket elküldtük a
        megadott email címre.
      </p>
      <p>Köszönjük, hogy megtisztelt bizalmával!</p>
    </div>
  );
}
