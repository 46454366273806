// @ts-check

import React, { useState } from "react";

import BrickPickerModal from "./BrickPickerModal";

// @ts-ignore
import * as styles from "../styles/components/brick-picker";
import TopBarButton from "./TopBarButton";

export default function BrickPicker({ className }) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${styles.brickPicker} ${className || ""}`}>
      <TopBarButton
        active={false}
        onClick={() => setOpen(!open)}
        icon="cube"
        text="Kocka választó"
      />
      {open && <BrickPickerModal setOpen={setOpen} />}
    </div>
  );
}
