import React from "react";

import { useEventListener } from "usehooks-ts";
import { ActionCreators } from "redux-undo";

import { useDispatch, useSelector } from "react-redux";
import { getIsSceneActive } from "../features/uiSlice";
import { setMode, toggleRotation } from "../features/builderSlice";
import { MODE_NAMES } from "../utils/constants";
import { isMac } from "../utils";

export function HotkeyProvider({ children }) {
  const isSceneActive = useSelector(getIsSceneActive);
  const dispatch = useDispatch();
  const handleSetMode = (mode) => dispatch(setMode(mode));
  const handleToggleRotation = () => dispatch(toggleRotation());

  useEventListener("keydown", (e) => {
    if (!isSceneActive) {
      return;
    }

    if (e.key === "t") {
      handleSetMode({ mode: MODE_NAMES.DELETION });
      return;
    }
    if (e.key === "é" || e.key === "e") {
      handleSetMode({ mode: MODE_NAMES.BUILD });
      return;
    }
    if (e.key === "f") {
      handleToggleRotation();
      return;
    }

    const isCorrectModifierKey = (isMac && e.metaKey) || (!isMac && e.ctrlKey);

    if (isCorrectModifierKey && !e.shiftKey && e.key === "z") {
      dispatch(ActionCreators.undo());
      return;
    }

    if (isMac && e.metaKey && e.shiftKey && e.key === "z") {
      dispatch(ActionCreators.redo());
      return;
    }

    if (!isMac && e.ctrlKey && e.key === "y") {
      dispatch(ActionCreators.redo());
      return;
    }
  });

  return children;
}
