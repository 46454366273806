// @ts-check

import { useEffect } from "react";

export function useKeyUp(targetKeyCode, callback) {
  function upHandler({ keyCode }) {
    if (keyCode === targetKeyCode) {
      callback();
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKeyCode, callback]);
}
