// @ts-check

import { configureStore } from "@reduxjs/toolkit";
import undoable from "redux-undo";

import builderReducer from "./features/builderSlice";
import sceneReducer from "./features/sceneSlice";
import uiReducer from "./features/uiSlice";

const store = configureStore({
  reducer: {
    builder: builderReducer,
    ui: uiReducer,
    scene: undoable(sceneReducer, { limit: 20 }),
  },
});

export default store;
