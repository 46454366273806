// @ts-check

import * as THREE from "three";
import { BACKGROUND_COLOR } from "../../../utils/constants";

export class Renderer extends THREE.WebGLRenderer {
  constructor(...args) {
    super(...args);
  }

  init(width, height) {
    // console.log(window.devicePixelRatio);
    this.setClearColor(BACKGROUND_COLOR);
    this.setPixelRatio(1);
    this.setSize(width, height);
    this.setPixelRatio(window.devicePixelRatio);

    this.gammaInput = true;
    this.gammaOutput = true;
    this.shadowMap.enabled = true;
    this.shadowMapSoft = true;
    this.shadowMap.type = THREE.PCFShadowMap;
  }
}
