export const base = 30;

export const bricks = [
  { x: 2, z: 1, top: false },
  { x: 4, z: 1, top: false },
  { x: 6, z: 1, top: false },
  { x: 2, z: 1, top: true },
  { x: 4, z: 1, top: true },
  { x: 6, z: 1, top: true },
  { x: 2, z: 2, top: false },
  { x: 4, z: 2, top: false },
  { x: 6, z: 2, top: false },
  { x: 2, z: 2, top: true },
  { x: 4, z: 2, top: true },
  { x: 6, z: 2, top: true },
];

export const colors = ["#a19f9f"];

export const BACKGROUND_COLOR = "#fafafa";
export const PLANE_COLOR = "#97c127";
// export const BACKGROUND_COLOR = 0x97c127;
export const GRID_COLOR = "#888";
export const GRID_CENTERLINE_COLOR = "#555";

export const MODE_NAMES = {
  BUILD: "build",
  DELETION: "deletion",
};

export const MODES = [
  {
    modeName: MODE_NAMES.BUILD,
    icon: "hammer",
    displayName: "Építés",
  },
  {
    modeName: MODE_NAMES.DELETION,
    icon: "close-round",
    displayName: "Törlés",
  },
];
